
import Vue from "vue";
import companyService from "@/services/companyService";

export default Vue.extend({
  name: "CompanySettings",
  async mounted() {
    try {
      // load user data
      this.initLoading = true;
      const response = await companyService.getCompany(this.$store.getters.companyId);
      if (response) {
        const company = response.data();
        this.companyName = company?.name || "";
      }
    } catch (error: any) {
      this.$notification.showError(error.message);
    } finally {
      this.initLoading = false;
    }
  },
  data() {
    return {
      loading: false,
      initLoading: false,
      companyName: "",
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    async validate() {
      try {
        const valid = (this.$refs.form as any).validate();
        if (valid) {
          this.loading = true;
          await companyService.saveCompany(this.$store.getters.companyId, { name: this.companyName });
          this.$emit("on-done", "Company Name Updated");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
});

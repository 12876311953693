
import Vue from "vue";
import DetailItem from "@/components/DetailItem.vue";
import PersonalInfo from "@/components/PersonalInfo.vue";
import PasswordSettings from "@/components/PasswordSettings.vue";
import TimeZoneSettings from "@/components/TimeZoneSettings.vue";
import CompanySettings from "@/components/company/CompanySettings.vue";
import AddressSettings from "@/components/company/AddressSettings.vue";
import WebsiteSettings from "@/components/company/WebsiteSettings.vue";
import NoReplyEmailSettings from "@/components/company/NoReplyEmailSettings.vue";
import AdminEmailSettings from "@/components/company/AdminEmailSettings.vue";
import ThemeSelector from "@/components/ThemeSelector.vue";
import themeService from "@/services/themeService";
import formService from "@/services/formService";
import userService from "@/services/userService";
import companyService from "@/services/companyService";
import AutoCompleteBox from "@/components/field-box/AutoCompleteBox.vue";
import { Company, Form, Setting, Theme } from "../../../shared/models";
import store from "@/store";
import TextFieldBox from "@/components/field-box/TextFieldBox.vue";
import { debounce } from "../../../shared/extensions/tlence";
import settingService from "@/services/settingService";
import SettingService from "../../../shared/services/SettingService";
import EmbeddedBox from "@/components/EmbeddedBox.vue";

export default Vue.extend({
  name: "Settings",
  components: {
    DetailItem,
    PersonalInfo,
    PasswordSettings,
    TimeZoneSettings,
    CompanySettings,
    AddressSettings,
    WebsiteSettings,
    ThemeSelector,
    NoReplyEmailSettings,
    AdminEmailSettings,
    AutoCompleteBox,
    TextFieldBox,
    EmbeddedBox,
  },
  mounted() {
    // load user data
    this.fetchData();
    this.loadAllThemes();
    this.loadAllForms();
  },
  data() {
    return {
      editMode: false,
      currentView: null,
      user: {} as any,
      loading: false,
      themes: [] as Theme[],
      company: null as Company | null,
      setting: null as Setting | null,
      rootForms: [] as Form[],
    };
  },
  methods: {
    async loadAllThemes() {
      const docs = await themeService.getAllThemes(this.companyId);
      const themes: Theme[] = [];
      docs.forEach((doc) => {
        var u = doc;
        u.id = doc.id;
        themes.push(u);
      });
      this.themes = themes;
    },
    async loadAllForms(callBack?) {
      try {
        this.loading = true;
        const docs = await formService.rootFormsList(this.companyId);
        const rootForms: Form[] = [];
        docs.forEach((doc) => {
          var form = doc.data() as Form;
          if (form.companyId && form.companyId == this.companyId) {
            form.id = doc.id;
            form.key = doc.id;
            form.children = [];
            rootForms.push(form);
          }
        });
        if (callBack) {
          callBack();
        }
        this.rootForms = rootForms;
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async setSelectedTheme(theme: string) {
      try {
        this.loading = true;
        await this.saveCompany({ defaultTheme: theme || null });
        this.onDone("Company Theme Updated");
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async setDefaultForm(form: string) {
      try {
        this.loading = true;
        await this.saveCompany({ defaultForm: form || null });
        this.onDone("Default Form Saved");
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async saveJwtClientId(jwtClientId: string) {
      try {
        this.loading = true;
        await this.saveSetting({
          jwtClientId: jwtClientId || "",
          jwtClientSecret: this.setting?.jwtClientSecret || "",
          id: SettingService.DefaultSettingId,
        });
        this.onDone("JWT Client Id Saved");
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async saveJwtClientSecret(jwtClientSecret: string) {
      try {
        this.loading = true;
        await this.saveSetting({
          jwtClientSecret: jwtClientSecret || "",
          jwtClientId: this.setting?.jwtClientId || "",
          id: SettingService.DefaultSettingId,
        });
        this.onDone("JWT Client Secret Saved");
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    fetchData() {
      this.loading = true;
      userService.getCurrentUserDataAsync(
        async (user) => {
          this.loading = false;
          this.user = { ...user };
          this.company = (await companyService.getCompany(this.user?.companyId))?.data() as Company;
          this.setting = await settingService.getSetting();
          await store.dispatch("setCompany", this.company);
          await store.dispatch("setSetting", this.setting);
        },
        (error) => {
          this.loading = false;
          this.$notification.showError(error.message);
        }
      );
    },
    onEditClick(currentView) {
      this.editMode = !this.editMode;
      this.currentView = currentView;
    },
    onDone(message) {
      this.$notification.showSuccess(message);
      this.editMode = false;
      this.currentView = null;
      this.fetchData();
    },
    onCancelClick() {
      this.editMode = false;
      this.currentView = null;
      this.fetchData();
    },
    async saveCompany(data: Partial<Company>) {
      await companyService.saveCompany(this.companyId, data);
    },
    async saveSetting(data: Setting) {
      await settingService.saveSetting(data);
    },
  },
  computed: {
    debouncedSaveJwtClientId(): (...args: any[]) => void {
      return debounce(this.saveJwtClientId, 1000);
    },
    debouncedSaveJwtClientSecret(): (...args: any[]) => void {
      return debounce(this.saveJwtClientSecret, 1000);
    },
    companyId(): string {
      return this.$store.getters.companyId;
    },
    userId(): string {
      return this.$store.getters.userId;
    },
    getFullName(): string {
      return this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : "";
    },
    getCompanyName(): string | null {
      return this.company?.name || null;
    },
    getCompanyDefaultForm(): string | null {
      return this.company?.defaultForm || null;
    },
    getCompanyAddress(): string | null {
      return this.company?.address?.address1 || null;
    },
    getCompanyWebsite(): string | null {
      return this.company?.website || null;
    },
    getCompanyNoReplyMail(): string | null {
      return this.company?.noReplyEmail || null;
    },
    getCompanyAdminMail(): string | null {
      return this.company?.adminEmail || null;
    },
    getCompanyTheme(): string | null {
      return this.company?.defaultTheme || null;
    },
  },
});


import Vue from "vue";
import TimeZone from "./TimeZone.vue";
import userService from "@/services/userService";

export default Vue.extend({
  name: "TimeZoneSettings",
  components: { TimeZone },
  async mounted() {
    // load user data
    try {
      this.initLoading = true;
      const response = await userService.getCurrentUserData();
      if (response) {
        const user = response.data();
        this.value = user?.timeZone || "";
      }
    } catch (error: any) {
      this.$notification.showError(error.message);
    } finally {
      this.initLoading = false;
    }
  },
  data() {
    return {
      value: "",
      loading: false,
      initLoading: false,
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    async validate(event) {
      const valid = (this.$refs.form as any).validate();
      if (valid) {
        try {
          this.loading = true;
          await userService.updateCurrentUserInfo({ timeZone: this.value });
          this.$emit("on-done", "Timezone Updated");
        } catch (error: any) {
          this.$notification.showError(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
});

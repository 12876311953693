
import Vue from "vue";
import userService from "@/services/userService";

export default Vue.extend({
  name: "PersonalInfo",
  async mounted() {
    // load user data
    const current = userService.currentUser();

    this.initLoading = true;
    try {
      const response = await userService.getCurrentUserData();
      if (response) {
        const user = response.data();
        this.initLoading = false;
        this.user = {
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          email: current?.email || "",
          telephone: user?.telephone || "",
        };
      }
    } catch (error: any) {
      this.initLoading = false;
      this.$notification.showError(error.message);
    }
  },
  methods: {
    async validate(event) {
      const valid = (this.$refs.form as any).validate();

      const user = this.user;

      if (valid) {
        this.loading = true;
        try {
          await userService.updateCurrentUserInfo({ firstName: user.firstName, lastName: user.lastName, telephone: user.telephone });
          this.loading = false;
          this.$emit("on-done", "Personal Info Updated");
        } catch (error: any) {
          this.loading = false;
          this.$notification.showError(error.message);
        }
      }
    },
  },
  data() {
    return {
      valid: false,
      initLoading: false,
      loading: false,
      user: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
});

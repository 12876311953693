
import Vue from "vue";
import companyService from "@/services/companyService";

export default Vue.extend({
  name: "WebsiteSettings",
  async mounted() {
    try {
      // load user data
      this.initLoading = true;
      const response = await companyService.getCompany(this.$store.getters.companyId);
      if (response) {
        const company = response.data();
        this.website = company?.website || "";
      }
    } catch (error: any) {
      this.$notification.showError(error.message);
    } finally {
      this.initLoading = false;
    }
  },
  data() {
    return {
      valid: false,
      website: "",
      loading: false,
      initLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        url: (value) => {
          const pattern =
            // eslint-disable-next-line no-useless-escape
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
          return pattern.test(value) || "Invalid url.";
        },
      },
    };
  },
  methods: {
    async validate(event) {
      try {
        const valid = (this.$refs.form as any).validate();
        if (valid) {
          this.loading = true;
          await companyService.saveCompany(this.$store.getters.companyId, { website: this.website });
          this.$emit("on-done", "Company Website Updated");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
});

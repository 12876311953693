
import Vue from "vue";
import { timeZones } from "../../../shared/constants";

export default Vue.extend({
  name: "TimeZone",
  props: ["value", "rules", "loading", "disabled"],
  data() {
    return {
      timeZones: timeZones,
    };
  },
  computed: {
    getTimeZones(): string[] {
      return this.timeZones.map((_) => _.label);
    },
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
});

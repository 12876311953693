
import Vue from "vue";

export default Vue.extend({
  name: "DetailItem",
  props: {
    title: String,
    isProtected: Boolean || undefined,
    value: String,
    hideEditButton: Boolean,
    disableEditButton: Boolean,
  },
  computed: {
    titleWidth() {
      switch ((this as any).$vuetify.breakpoint.name) {
        case "xs":
          return { width: "auto", "margin-right": "10px" };
        case "sm":
          return { width: "auto", "margin-right": "10px" };
        case "md":
          return { width: "150px" };
        case "lg":
          return { width: "150px" };
        case "xl":
          return { width: "150px" };
      }
      return null;
    },
  },
});

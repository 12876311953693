
import Vue from "vue";
import userService from "@/services/userService";

export default Vue.extend({
  name: "PasswordSettings",
  data: function () {
    return {
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      loading: false,
      valid: false,
      password: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    async validate(event) {
      const valid = (this.$refs as any).form.validate();

      if (this.password.confirmPassword === this.password.newPassword) {
        if (valid) {
          this.loading = true;
          try {
            await userService.changeCurrentUserPassword(this.password.currentPassword, this.password.newPassword);
            this.$emit("on-done", "Password has changed successfully");
          } catch (error: any) {
            console.error(error);
            this.$notification.showError(error.message);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.$notification.showError("Password and confirm are not the same");
      }
    },
  },
});


import Vue from "vue";
import companyService from "@/services/companyService";

export default Vue.extend({
  name: "AdminEmailSettings",
  async mounted() {
    try {
      // load user data
      this.initLoading = true;
      const response = await companyService.getCompany(this.$store.getters.companyId);
      if (response) {
        const company = response.data();
        this.adminEmail = company?.adminEmail || "";
      }
    } catch (error: any) {
      this.$notification.showError(error.message);
    } finally {
      this.initLoading = false;
    }
  },
  data() {
    return {
      valid: false,
      adminEmail: "",
      loading: false,
      initLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Email.";
        },
      },
    };
  },
  methods: {
    async validate(event) {
      try {
        const valid = (this.$refs.form as any).validate();
        if (valid) {
          this.loading = true;
          await companyService.saveCompany(this.$store.getters.companyId, { adminEmail: this.adminEmail });
          this.$emit("on-done", "Company Admin Email Updated");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
});

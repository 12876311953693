
import Vue from "vue";
import { countryList } from "../../../../shared/constants";
import companyService from "@/services/companyService";

export default Vue.extend({
  name: "AddressSettings",
  async mounted() {
    try {
      // load user data
      this.initLoading = true;
      const response = await companyService.getCompany(this.$store.getters.companyId);
      if (response) {
        const company = response.data();
        this.address = {
          ...this.address,
          ...company?.address,
        };
      }
    } catch (error: any) {
      this.$notification.showError(error.message);
    } finally {
      this.initLoading = false;
    }
  },
  data() {
    return {
      valid: false,
      countries: [...countryList],
      loading: false,
      initLoading: false,
      address: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        postalCode: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    async validate(event) {
      const valid = (this.$refs.form as any).validate();
      if (valid) {
        try {
          this.loading = true;
          await companyService.saveCompany(this.$store.getters.companyId, { address: this.address });
          this.$emit("on-done", "Company Address Updated");
        } catch (error: any) {
          this.$notification.showError(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
